@import '../variables';

.close {
  position: fixed;
  top: 0;
  right: 0;

  display: block;
  color: black;
  text-align: center;

  font-weight: 100;
  font-size: 30px;
  padding: 15px;
  line-height: 1em;
  width: 1em;

  @media (min-width: $max-width) {
    display: none;
  }
}
