@import-normalize;
@import 'variables';

body {
  background: $bg;
  margin: 0;
}

a {
  text-decoration: none;
}
